import "../styles/css/home.css"
import "../styles/css/navbar.css"
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { RiMenu5Fill } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { useState } from "react";
import { LiaDonateSolid } from "react-icons/lia";
import { LuHelpingHand } from "react-icons/lu";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsPeople } from "react-icons/bs";
import { MdOutlineVolunteerActivism, MdOutlineBloodtype, MdOutlineEmail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { PiBowlFood } from "react-icons/pi";
import { TfiHome } from "react-icons/tfi";
import { GiMoneyStack } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone, FaFacebookF, FaTwitter, FaLinkedinIn, FaGooglePlusG, FaInstagram } from "react-icons/fa";
import about from "../styles/images/about.jpg";
import mission from "../styles/images/mision.png";
import img1 from "../styles/images/gallery/img1.jpg"
import img2 from "../styles/images/gallery/img2.jpg"
import img3 from "../styles/images/gallery/img3.jpg"
import img4 from "../styles/images/gallery/img4.jpg"
import img5 from "../styles/images/gallery/img5.jpg"
import img6 from "../styles/images/gallery/img6.jpg"
import img7 from "../styles/images/gallery/img7.jpg"
import img8 from "../styles/images/gallery/img8.jpg"
import img9 from "../styles/images/gallery/img9.jpg"
import img10 from "../styles/images/gallery/img10.jpeg"
import img11 from "../styles/images/gallery/img11.jpg"
import img12 from "../styles/images/gallery/img12.jpg"
import img13 from "../styles/images/gallery/img13.jpeg"
import img14 from "../styles/images/gallery/img14.jpeg"
import img15 from "../styles/images/gallery/img15.jpeg"
import img16 from "../styles/images/gallery/img16.jpeg"
import logo from "../styles/images/love.svg"
import jsonp from 'jsonp';
const Home = () => {
    const navigate = useNavigate();
    const [clicked, setClicked] = useState(false);
    const [email, setEmail] = useState('');

    const handleClick = () => {
        setClicked(!clicked);
    };
    const onSubmit = e => {
        e.preventDefault();
        const url = 'https://gmail.us21.list-manage.com/subscribe/post?u=1a634e438d8b11650687328fc&amp;id=8ba4cfdd9f&amp;f_id=000fe6e6f0';
        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
            // const { msg, result } = data
            // do something with response
        });
        alert("Response succesfull!");
        e.target.reset();
    };
    return (
        <>
            <nav className="navbar">
                <div className={clicked ? "container active" : "container"}>
                    <div onClick={() => navigate("/")} className="logo">
                        <div className="image">
                            <img src={logo} alt="" />
                        </div>
                        <div className="text">
                            <h1>NO Judgement</h1>
                            <h2>Help Others, Be Happy</h2>
                        </div>
                    </div>
                    <div className="nav-elements">
                        <ul>
                            <li onClickCapture={handleClick}>
                                <a href="#">Home</a>
                            </li>
                            <li onClickCapture={handleClick}>
                                <a href="#about">About</a>
                            </li>
                            <li onClickCapture={handleClick}>
                                <a href="#gallery">Gallery</a>
                            </li>
                            <li onClickCapture={handleClick}>
                                <a href="/contact-us">Contact us</a>
                            </li>
                            <li>
                                <a href="/contact-us">Donate Now</a>

                            </li>
                        </ul>
                    </div>
                    <div onClickCapture={handleClick} className="menu">
                        {clicked ? <IoIosClose /> : <RiMenu5Fill />}
                    </div>
                </div>
            </nav>
            <section className="main" id="main">
                <div className="main-container">
                    <motion.div
                        initial={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                        className="main-details">
                        <h1>❝ Empowering Lives, Building Futures ❞</h1>
                        <h2>Join Us in Bridging the Gap for a Better Tomorrow.</h2>
                        <h3>With Compassion & Understanding</h3>
                        <a href="#about2"><button>Learn More</button></a>
                    </motion.div>
                </div>
            </section>
            <section className="about" id="about">
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    className="a-card">
                    <LiaDonateSolid className="icon" />
                    <h1>Give Donation</h1>
                    <div className="lines">
                        <hr className="one" />
                        <hr className="two" />
                    </div>
                    <p>Join us in making a positive impact. Give today and be a catalyst for change.</p>
                </motion.div>
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    className="a-card">
                    <LuHelpingHand className="icon" />
                    <h1>Help The Homeless</h1>
                    <div className="lines">
                        <hr className="one" />
                        <hr className="two" />
                    </div>
                    <p>Shelter, assistance, and care – join us in making a difference today.</p>
                </motion.div>
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    className="a-card">
                    <GiTakeMyMoney className="icon" />
                    <h1>Become an Investor</h1>
                    <div className="lines">
                        <hr className="one" />
                        <hr className="two" />
                    </div>
                    <p>Your investment helps builds a brighter future. Join us in making a lasting difference.</p>
                </motion.div>
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    className="a-card">
                    <MdOutlineVolunteerActivism className="icon" />
                    <h1>Become a Volunteer</h1>
                    <div className="lines">
                        <hr className="one" />
                        <hr className="two" />
                    </div>
                    <p>Your time and skills can make a real impact. Become a volunteer today.</p>
                </motion.div>
            </section>
            <section className="about-2" id="about2">
                <div className="about-2-row">
                    <motion.div
                        initial={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                        className="about-2-r1">
                        <img src={about} alt="" />
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                        className="about-2-r2">
                        <div className="about-heading">
                            <h1>A Charity with Mission</h1>
                            <hr className="one" />
                        </div>
                        <h2>We are <span>NO judgement</span></h2>
                        <p className="p1">NO judgement 0928 is an organization striving to bring resources provision and shelter to the homeless. We access, develop, and provide social services to individuals through our beneficial services. We help homeless people by providing shelter for them, help with any other services they may need whether it is a job or a babysitter, a car, a bill paid, mental healthcare, as we are a place where you can get help without judgment</p>
                        <p className="p2">We solicit temporary housing and its funding through sponsor and investors. At NO judgement 0928, our goal is to promote an atmosphere and environment of inclusiveness.</p>
                        <h4>Sade Pickard</h4>
                    </motion.div>
                </div>
            </section>
            <section className="volunteer">
                <div className="volunteer-container">
                    <motion.h1
                        initial={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                    >We help thousands of person's,
                        arrange food & provide houses for them</motion.h1>
                    <motion.h2
                        initial={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                    >❝  Join us & Become a Volunteer  ❞</motion.h2>
                </div>
            </section>
            <section className="mission">
                <motion.h1
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                >Mission & <span>Goals</span></motion.h1>
                <motion.p
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                >Many children and poor people are at high risk of becoming homeless</motion.p>
                <div className="mission-row">
                    <div className="mission-row-1">
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            className="m-c1">
                            <div className="mission-col">
                                <TfiHome className="mission-icon" />
                                <div className="mission-lines">
                                    <hr className="m-one" />
                                    <hr className="m-two" />
                                </div>
                                <h1>Homeless Persons</h1>
                            </div>
                            <div className="overlay">
                                <div className="overlay-content">
                                    We make a difference in people's lives by providing them with food, shelter, and other beneficial resources.
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            className="m-c1">
                            <div className="mission-col">
                                <PiBowlFood className="mission-icon" />
                                <div className="mission-lines">
                                    <hr className="m-one" />
                                    <hr className="m-two" />
                                </div>
                                <h1>Food</h1>
                            </div>
                            <div className="overlay">
                                <div className="overlay-content">
                                    We improve people's lives by giving them access to food and shelter
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            className="m-c1">
                            <div className="mission-col">
                                <CiGlobe className="mission-icon" />
                                <div className="mission-lines">
                                    <hr className="m-one" />
                                    <hr className="m-two" />
                                </div>
                                <h1>Humanitarian services</h1>
                            </div>
                            <div className="overlay">
                                <div className="overlay-content">
                                    We provide top quality humanitarian services
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            className="m-c1">
                            <div className="mission-col">
                                <MdOutlineBloodtype className="mission-icon" />
                                <div className="mission-lines">
                                    <hr className="m-one" />
                                    <hr className="m-two" />
                                </div>
                                <h1>Improving Quality of Life</h1>
                            </div>
                            <div className="overlay">
                                <div className="overlay-content">
                                    We aim to make life worth living for the underprivileged while upholding high ethical and moral standards
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            className="m-c1">
                            <div className="mission-col">
                                <BsPeople className="mission-icon" />
                                <div className="mission-lines">
                                    <hr className="m-one" />
                                    <hr className="m-two" />
                                </div>
                                <h1>Building Communities</h1>
                            </div>
                            <div className="overlay">
                                <div className="overlay-content">
                                    We asssit in building and developing homeless communities
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            whileInView={{ scale: 1, opacity: 1 }}
                            className="m-c1">
                            <div className="mission-col">
                                <GiMoneyStack className="mission-icon" />
                                <div className="mission-lines">
                                    <hr className="m-one" />
                                    <hr className="m-two" />
                                </div>
                                <h1>Crowdfunding</h1>
                            </div>
                            <div className="overlay">
                                <div className="overlay-content">
                                    We solicit temporary housing and its funding through sponsor and investors.
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="mission-row-2">
                        <img src={mission} alt="" />
                    </div>
                </div>
            </section>
            <section className="gallery" id="gallery">
                <h1>Our Gallery</h1>
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    class="grid-container">
                    <div class="grid-item">
                        <img src={img1} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img2} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img3} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img4} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img5} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img6} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img7} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img8} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img9} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img10} alt="" className="image"/>
                    </div>
                    <div class="grid-item">
                        <img src={img11} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img12} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img13} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img14} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img15} alt="" />
                    </div>
                    <div class="grid-item">
                        <img src={img16} alt="" />
                    </div>
                </motion.div>
            </section>
            <section className="newsletter">
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    className="newsletter-details">
                    <h1>Subscribe to Newsletter</h1>
                    <h2>Stay in the Loop, Embrace the News: Your Weekly Dose of Impactful Updates!</h2>
                </motion.div>
                <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    className="newsletter-input">
                    <div className="input-wrap">
                        <form onSubmit={onSubmit}>
                            <input type="email"
                                name="EMAIL"
                                required 
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your email..." />
                            <button>
                                <button
                                type="submit"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M5 12l14 0"></path>
                                        <path d="M13 18l6 -6"></path>
                                        <path d="M13 6l6 6"></path>
                                    </svg>
                                </button>
                            </button>
                        </form>
                    </div>
                </motion.div>
            </section>
            <footer>
                <div className="footer">
                    <div className="footer1">
                        <div className="logo-footer">
                            <div className="image">
                                <img src={logo} alt="" />
                            </div>
                            <div className="text">
                                <h1>NO Judgement</h1>
                                <h2>Help Others, Be Happy</h2>
                            </div>
                        </div>
                        <div className="footer-details">
                            <p>We are dedicated to providing comprehensive support, from essential services and employment assistance to childcare and mental healthcare.</p>
                        </div>
                        <div className="social-details">
                            <div className="social-icon">
                                <a href="">
                                    <FaTwitter className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaLinkedinIn className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaGooglePlusG className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaFacebookF className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaInstagram className="a" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer2">
                        <h1>Our Pages</h1>
                        <h2>Home</h2>
                        <h2>About</h2>
                        <h2>Gallery</h2>
                        <h2>Contact Us</h2>
                        <h2>Donate Now</h2>
                    </div>
                    <div className="footer3">
                        <h1>Contact</h1>
                        <div className="address">
                            <FaLocationDot className="address-icon" />
                            <h1>Greensboro, North Carolina</h1>
                        </div>
                        <div className="address">
                            <FaPhone className="address-icon" />
                            <h1>333-933-1179</h1>
                        </div>
                        <div className="address">
                            <MdOutlineEmail className="address-icon" />
                            <h1>nojudgement0928@gmail.com</h1>
                        </div>
                    </div>
                </div>
                <hr />
                <h1 className="copyright">
                    Copyrights © 2023 <span>NO Judgement</span>. All rights reserved.
                </h1>
            </footer>
        </>
    );
}

export default Home;
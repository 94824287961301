import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from './pages/home';
import Contact from './pages/contact';

function App() {
  return (
    <>
    <Router>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/contact-us" element={<Contact/>} />
      </Routes>
    </Router>
    </>
  );
}

export default App;

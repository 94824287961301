import "../styles/css/navbar.css";
import "../styles/css/contact.css"
import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { RiMenu5Fill } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import logo from "../styles/images/love.svg"
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { HashLink } from "react-router-hash-link";
import jsonp from 'jsonp';
import { FaPhone, FaGlobe, FaFacebookF, FaTwitter, FaLinkedinIn, FaGooglePlusG, FaInstagram } from "react-icons/fa";



const Contact = () => {

    const navigate = useNavigate();
    const [clicked, setClicked] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [service, setService] = useState("");
    const [message, setMessage] = useState("");
    const [_email, _setEmail] = useState('');


    const handleClick = () => {
        setClicked(!clicked);
    };
    const submit = (e) => {
        e.preventDefault();
        if (name === "" || email === "" || phone === "" || service === "" || message === "") {
            alert("All fields are required")
        } else {
            const config = {
                Username: process.env.REACT_APP_USERNAME,
                Password: process.env.REACT_APP_PASSWORD,
                Host: process.env.REACT_APP_HOST,
                Port: process.env.REACT_APP_PORT,
                To: 'nojudgement0928@gmail.com',
                From: 'mozman7777@gmail.com',
                Subject: `You have a new mail from ${name}, email:${email}`,
                Body: `New email from ${name}, phone:${phone}, service:${service}, email:${email}. ${message}`
            }
            if (window.Email) {
                window.Email.send(config).then((message) => alert("Email sent successfully"),navigate("/"))
            }
        }
    }
    const onSubmit = e => {
        e.preventDefault();
        const url = 'https://gmail.us21.list-manage.com/subscribe/post?u=1a634e438d8b11650687328fc&amp;id=8ba4cfdd9f&amp;f_id=000fe6e6f0';
        jsonp(`${url}&EMAIL=${_email}`, { param: 'c' }, (_, data) => {
            // const { msg, result } = data
            // do something with response
        });
        alert("Response succesfull!");
        e.target.reset();
    };
    return (
        <>
            <nav className="navbar">
                <div className={clicked ? "container active" : "container"}>
                    <div onClick={() => navigate("/")} className="logo">
                        <div className="image">
                            <img src={logo} alt="" />
                        </div>
                        <div className="text">
                            <h1>NO Judgement</h1>
                            <h2>Help Others, Be Happy</h2>
                        </div>
                    </div>
                    <div className="nav-elements">
                        <ul>
                            <li onClickCapture={handleClick}>

                                <HashLink to="/#">Home</HashLink>
                            </li>
                            <li onClickCapture={handleClick}>

                                <HashLink to="/#about">About</HashLink>
                            </li>
                            <li onClickCapture={handleClick}>

                                <HashLink to="/#gallery">Gallery</HashLink>
                            </li>
                            <li onClickCapture={handleClick}>
                                <HashLink to="/contact-us">Contact us</HashLink>
                            </li>
                            <li>

                                <HashLink to="/contact-us">Donate Now</HashLink>

                            </li>
                        </ul>
                    </div>
                    <div onClickCapture={handleClick} className="menu">
                        {clicked ? <IoIosClose /> : <RiMenu5Fill />}
                    </div>
                </div>
            </nav>
            <section className="contact">
                <div className="contact-container">
                    <h1>Contact Us</h1>
                </div>
            </section>
            <section className="contact-row">
                <div className="message-col">
                    <h1>Send A Message</h1>
                    <div className="message-lines">
                        <hr className="m1" />
                        <hr className="m2" />
                    </div>
                    <form onSubmit={submit}>
                        <div className="form-container">
                            <div className="form-row1">
                                <div className="input-col">
                                    <h2>Name</h2>
                                    <input type="text" name="name" onChange={(e) => setName(e.target.value)} value={name} />
                                </div>
                                <div className="input-col">
                                    <h2>Email</h2>
                                    <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>
                            </div>
                            <div className="form-row1">
                                <div className="input-col">
                                    <h2>Phone</h2>
                                    <input type="text" name="phone" onChange={(e) => setPhone(e.target.value)} value={phone} />
                                </div>
                                <div className="input-col">
                                    <h2>Sevice</h2>
                                    <input type="text" name="service" onChange={(e) => setService(e.target.value)} value={service} />
                                </div>
                            </div>
                            <div className="input-text-area">
                                <h2>Message</h2>
                                <textarea rows="10" cols="80" type="text" name="message" onChange={(e) => setMessage(e.target.value)} value={message
                                } />
                            </div>
                            <div className="button-container">
                                <input type="submit" value="Submit now" />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="address-col">
                    <h1>Address Information</h1>
                    <div className="message-lines">
                        <hr className="m1" />
                        <hr className="m2" />
                    </div>
                    <p>For inquiries, partnerships, or to learn more about our mission and services, feel free to reach out. We value your connection and look forward to making a difference together.</p>
                    <div className="address-row">
                        <FaLocationDot className="a-icon" />
                        <div className="address-column">
                            <h1>Address</h1>
                            <h2>Greensboro, North Carolina</h2>
                        </div>
                    </div>
                    <div className="address-row">
                        <FaPhone className="a-icon" />
                        <div className="address-column">
                            <h1>Phone</h1>
                            <h2>333-933-1179</h2>
                        </div>
                    </div>
                    <div className="address-row">
                        <MdOutlineEmail className="a-icon" />
                        <div className="address-column">
                            <h1>Email</h1>
                            <h2>nojudgement0928@gmail.com</h2>
                        </div>
                    </div>
                    <div className="address-row">
                        <FaGlobe className="a-icon" />
                        <div className="address-column">
                            <h1>Website</h1>
                            <h2>www.nojudgement0928.com</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newsletter">
                <div className="newsletter-details">
                    <h1>Subscribe to Newsletter</h1>
                    <h2>Stay in the Loop, Embrace the News: Your Weekly Dose of Impactful Updates!</h2>
                </div>
                <div className="newsletter-input">
                    <div className="input-wrap">
                    <form onSubmit={onSubmit}>
                            <input type="email"
                                name="EMAIL"
                                required 
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your email..." />
                            <button>
                                <button
                                type="submit"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M5 12l14 0"></path>
                                        <path d="M13 18l6 -6"></path>
                                        <path d="M13 6l6 6"></path>
                                    </svg>
                                </button>
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <footer>
                <div className="footer">
                    <div className="footer1">
                        <div className="logo-footer">
                            <div className="image">
                                <img src={logo} alt="" />
                            </div>
                            <div className="text">
                                <h1>NO Judgement</h1>
                                <h2>Help Others, Be Happy</h2>
                            </div>
                        </div>
                        <div className="footer-details">
                            <p>We are dedicated to providing comprehensive support, from essential services and employment assistance to childcare and mental healthcare.</p>
                        </div>
                        <div className="social-details">
                            <div className="social-icon">
                                <a href="">
                                    <FaTwitter className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaLinkedinIn className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaGooglePlusG className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaFacebookF className="a" />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="">
                                    <FaInstagram className="a" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer2">
                        <h1>Our Pages</h1>
                        <h2>Home</h2>
                        <h2>About</h2>
                        <h2>Gallery</h2>
                        <h2>Contact Us</h2>
                        <h2>Donate Now</h2>
                    </div>
                    <div className="footer3">
                        <h1>Contact</h1>
                        <div className="address">
                            <FaLocationDot className="address-icon" />
                            <h1>Greensboro, North Carolina</h1>
                        </div>
                        <div className="address">
                            <FaPhone className="address-icon" />
                            <h1>333-933-1179</h1>
                        </div>
                        <div className="address">
                            <MdOutlineEmail className="address-icon" />
                            <h1>nojudgement0928@gmail.com</h1>
                        </div>
                    </div>
                </div>
                <hr />
                <h1 className="copyright">
                    Copyrights © 2023 <span>NO Judgement</span>. All rights reserved.
                </h1>
            </footer>
        </>
    );
}

export default Contact